import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { JwtService } from './core/services/jwt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: object, private jwtService: JwtService) {

  }
  isLogedIn(): boolean {
    if (this.jwtService.getToken()) {
      return true;
    } else {
      return false;
    }
  }
  isOnline(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return navigator.onLine;
    } else {
      return true;
    }
  }
}
