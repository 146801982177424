import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private apiService: ApiService,
    ) {
    }
    loginUser(data): Observable<any> {
        return this.apiService.post('/api/v1/user/loginUser', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    registerUser(data): Observable<any> {
        return this.apiService.post('/api/v1/user/registerUser', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    registerUser1(data): Observable<any> {
        return this.apiService.post('/api/v1/user/registerUser1', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    checkEmailExist(data): Observable<any> {
        return this.apiService.post('/api/v1/user/checkEmailExist', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    savePaymentDetails(data): Observable<any> {
        return this.apiService.post('/api/v1/user/savePaymentDetails', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    totalAttendee(data): Observable<any> {
        return this.apiService.get('/api/v1/user/totalAttendee', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getAttendeCount(data): Observable<any> {
        return this.apiService.get('/api/v1/user/getAttendeCount', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    updateProfile(data): Observable<any> {
        return this.apiService.post('/api/v1/user/updateProfile', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getLeaderboard(data): Observable<any> {
        return this.apiService.get('/api/v1/user/getLeaderboard', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getCountries(data): Observable<any> {
        return this.apiService.get('/api/v1/user/countries', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getStates(data): Observable<any> {
        return this.apiService.get('/api/v1/user/states', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getCities(data): Observable<any> {
        return this.apiService.get('/api/v1/user/cities', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    locationWiseUsers(data): Observable<any> {
        return this.apiService.get('/api/v1/user/locationWiseUsers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
}
