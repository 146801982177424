import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtService } from './jwt.service';
import { EventService } from './event.service';


@Injectable()
export class NoAuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private eventService: EventService,
        private jwtService: JwtService
    ) { }

    canActivate(): boolean {
        if (this.jwtService.getToken()) {
            this.router.navigate(['home']);
            return false;
        } else {
            if (this.eventService.getEventId()) {
                return true;
            } else {
                this.router.navigate(['not-found']);
                return false;
            }
        }
    }
}