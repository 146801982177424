import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtService } from './jwt.service';
import { EventService } from './event.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        private eventService: EventService,
        private jwtService: JwtService
    ) { }

    canActivate(): boolean {
        if (this.jwtService.getToken()) {
            return true;
        } else {
            if (this.eventService.getEventId()) {
                this.router.navigate(['/']);
                return false;
            } else {
                this.router.navigate(['/not-found']);
                return false;
            }
        }
    }
}
