import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    constructor() { }

    getToken(): string {
        return window.sessionStorage.jwtToken;
    }
    getUserData(): string {
        return window.sessionStorage.userData;
    }
    saveToken(token: string): void {
        window.sessionStorage.jwtToken = token;
    }
    saveUserData(data: string): void {
        window.sessionStorage.userData = data;
    }
    destroyToken(): void {
        window.sessionStorage.removeItem('jwtToken');
        window.sessionStorage.removeItem('userData');
    }
}
