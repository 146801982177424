import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor(
        private apiService: ApiService
    ) {
    }
    getEventId(): string {
        return window.sessionStorage.EventId;
    }

    saveEventId(id: string): void {
        window.sessionStorage.EventId = id;
    }

    agenda(data): Observable<any> {
        return this.apiService.post('/api/v1/user/agenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    cometKeys(data): Observable<any> {
        return this.apiService.get('/api/v1/user/chat/keys', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    eventDetails(data): Observable<any> {
        return this.apiService.get('/api/v1/user/eventDetails', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    eventDetailsById(data): Observable<any> {
        return this.apiService.get('/api/v1/user/eventDetailsById', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    engagementDetailsById(data): Observable<any> {
        return this.apiService.get('/api/v1/user/engagementDetailsById', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    agendaDetail(data): Observable<any> {
        return this.apiService.get('/api/v1/user/agendaDetail', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    eventFeedbackQuestion(data): Observable<any> {
        return this.apiService.get('/api/v1/user/feedbackQuestion', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    userFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/user/userFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBooths(data): Observable<any> {
        return this.apiService.get('/api/v1/user/booths', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    searchBooths(data): Observable<any> {
        return this.apiService.get('/api/v1/user/booths/search', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBoothDetails(data): Observable<any> {
        return this.apiService.get('/api/v1/user/getBoothDetails', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    createboothMeeting(data): Observable<any> {
        return this.apiService.post('/api/v1/user/createboothMeeting', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createLoungeMeeting(data): Observable<any> {
        return this.apiService.post('/api/v1/user/createLoungeMeeting', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    bookedMeetings(data): Observable<any> {
        return this.apiService.get('/api/v1/user/bookedMeetings', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    AddToBriefcase(data): Observable<any> {
        return this.apiService.post('/api/v1/user/briefcase', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    sendBriefcaseToMail(data): Observable<any> {
        return this.apiService.get('/api/v1/user/sendBriefcaseToMail', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getBriefcaseItem(data): Observable<any> {
        return this.apiService.get('/api/v1/user/briefcase', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    addPoints(data): Observable<any> {
        return this.apiService.post('/api/v1/user/addPoints', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getFields(data): Observable<any> {
        return this.apiService.get('/api/v1/user/fields', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    agendaFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/user/agendaFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    agendaRating(data): Observable<any> {
        return this.apiService.post('/api/v1/user/agendaRating', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    criteria(data): Observable<any> {
        return this.apiService.get('/api/v1/user/criteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    lounge(data): Observable<any> {
        return this.apiService.get('/api/v1/user/lounge', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getLoungeDetails(data): Observable<any> {
        return this.apiService.get('/api/v1/user/getLoungeDetails', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    lbookedMeetings(data): Observable<any> {
        return this.apiService.get('/api/v1/user/lbookedMeetings', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    loungeMeetingDetails(data): Observable<any> {
        return this.apiService.get('/api/v1/user/loungeMeetingDetails', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createBoothFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/user/createBoothFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    addTracking(data): Observable<any> {
        return this.apiService.post('/api/v1/user/addTracking', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    helpDeskChatUsers(data): Observable<any> {
        return this.apiService.get('/api/v1/user/helpDeskChatUsers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

}
