<!-- <app-header *ngIf="isLogedIn()"></app-header> -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<router-outlet *ngIf="isOnline();else isOffline"></router-outlet>
<ng-template #isOffline>
  <div class="content-wrapper">
    <section class="content">
      <div class="page-wrap">
        <h3 class="text-danger">No Internet Connection</h3>
      </div>
    </section>
  </div>
</ng-template>
<!-- <app-footer *ngIf="isLogedIn()"></app-footer> -->