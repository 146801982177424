import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';
import { NoAuthGuardService } from './core/services/no-auth-guard.service';


const routes: Routes = [
  { path: 'login', canActivate: [NoAuthGuardService], loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'home', canActivate: [AuthGuardService], loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'event', canActivate: [AuthGuardService], loadChildren: () => import('./event/event.module').then(m => m.EventModule) },
  { path: '', loadChildren: () => import('./event-home/event-home.module').then(m => m.EventHomeModule) },
  { path: 'register', canActivate: [NoAuthGuardService], loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'reset-password', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: 'landing/:hash', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
