// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://admin.test.eventuresindia.com',
  chat_api_url: "https://chat.test.eventuresindia.com",
  meeting_api_url: "https://meetings.test.eventuresindia.com",
  accessKeyId: 'AKIAV4HIOJRV2FLAUNEA',
  secretAccessKey: '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',
  region: 'ap-south-1',
  Bucket: 'virtualevent.s3.public'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
