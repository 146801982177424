import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket;
    constructor() { }
    setupSocketConnection(event_id, user_id, url) {
        this.socket = io(environment.api_url, { query: 'event_id=' + event_id + '&user_id=' + user_id + '&url=' + url + '' });
    }
    public getMessages = () => {
        return Observable.create((observer) => {
            this.socket.on('notification', (message: any) => {
                observer.next(message);
            });
        });
    }
    public getLocations = () => {
        return Observable.create((observer) => {
            this.socket.on('location', (data: any) => {
                observer.next(data);
            });
        });
    }
    public sendMessage(message) {
        this.socket.emit('location', message);
    }
}